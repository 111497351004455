import { BrowserRouter as Router } from "react-router-dom";
import { withSeparateApp } from "hocs/withSeparateApp";
import AppProvider from "providers/app";
import NotificationHandler from "../helpers/notification";

const AppRoutes = () => {
  return (
    <Router>
      <AppProvider>{withSeparateApp()}</AppProvider>
      <NotificationHandler />
    </Router>
  );
};

export default AppRoutes;

export const AI_PROCESSING_IMG = require("./ai_processing.gif");
export const QUOTE_LEFT_CONTACT_BLUR_IMG = require("./quote_left_to_contact.png");
export const CONTACT_INFO_BLUR_IMG = require("./blur/contact_info.png");
export const REGISTER_BANNER_EN_DESKTOP_IMG =
  "/images/banner/register_banner_en_desktop.webp";
export const REGISTER_BANNER_EN_MOBILE_IMG =
  "/images/banner/register_banner_en_mobile.webp";
export const REGISTER_BANNER_VN_DESKTOP_IMG =
  "/images/banner/register_banner_vn_desktop.webp";
export const REGISTER_BANNER_VN_MOBILE_IMG =
  "/images/banner/register_banner_vn_mobile.webp";
export const BUY_PLAN_BANNER_EN_DESKTOP_IMG =
  "/images/banner/buy_plan_banner_en_desktop.webp";
export const BUY_PLAN_BANNER_EN_MOBILE_IMG =
  "/images/banner/buy_plan_banner_en_mobile.webp";
export const BUY_PLAN_BANNER_VN_DESKTOP_IMG =
  "/images/banner/buy_plan_banner_vn_desktop.webp";
export const BUY_PLAN_BANNER_VN_MOBILE_IMG =
  "/images/banner/buy_plan_banner_vn_mobile.webp";

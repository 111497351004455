import {
  BIZ_DOMAIN,
  BUYER_CENTER_DOMAIN,
  FRESHDI_HOME_DOMAIN,
  LANDING_DOMAIN,
  SOURCING_DOMAIN,
  SUPPLIER_CENTER_DOMAIN,
} from "./schemas";

export const PATHS = {
  home: "/",
  desk: "/desk",
  myQuotations: "/my-quotations",
  myQuotationsFull: BIZ_DOMAIN + "/my-quotations",
  myQuotaionDetail: "/my-quotations/:quotationid",
  quotaionDetail: "/quotations/:quotationid",
  socialRegister: "/social-register",
  sourcingRequests: "/sourcing-requests",
  sourcingRequestsFull: SOURCING_DOMAIN + "/sourcing-requests",
  login: "/login",
  register: "/register",
  registerFull: FRESHDI_HOME_DOMAIN + "/register",
  post_rfq: `${FRESHDI_HOME_DOMAIN}/quick-rfq`,
  category: "/category",
  requestDetail: "/request",
  policy: `${LANDING_DOMAIN}/policy`,
  pricing: `${LANDING_DOMAIN}/pricing`,
  intro: `${LANDING_DOMAIN}/vi/freshdi-tien-phong-trong-xuat-khau-nong-san`,
  recommendation: "/recommondation",
  orders: "/orders",
  inquiries: "/inquiries",
  products: "/products",
  createProductsFull: BIZ_DOMAIN + "/products/create",
  setting: "/setting",
  settingFull: BIZ_DOMAIN + "/setting",
  contacts: "/contacts",
  searchSuppliers: `${FRESHDI_HOME_DOMAIN}/search-supplier`,
  searchProducts: `${FRESHDI_HOME_DOMAIN}/search`,
  buyerCenter: BUYER_CENTER_DOMAIN,
  supplierCenter: SUPPLIER_CENTER_DOMAIN,
  buyerFAQs: `${BUYER_CENTER_DOMAIN}/`,
  supplierFAQs: `${SUPPLIER_CENTER_DOMAIN}/faqs`,
  contactUs: `${LANDING_DOMAIN}/contact-us`,
  startSelling: `${BIZ_DOMAIN}/products`,
  smartTraceFull: `${LANDING_DOMAIN}/smart-traceability-solution`,
  termCondition: `${LANDING_DOMAIN}/terms-and-conditions`,
  buyers: "/buyers",
  buyersFull: SOURCING_DOMAIN + "/buyers",
  buyerCategory: "/buyer-category",
  suppliers: "/suppliers",
  supplierPricing: LANDING_DOMAIN + "/membership/membership-for-supplier",
  buyerPricing: LANDING_DOMAIN + "/membership/membership-for-buyer",
  membership: `/membership`,
  membershipFull: `${BIZ_DOMAIN}/membership`,
  analytics: `/analytics`,
  overview: "/desk",
  smartTrace: "/smart-trace",
  buyerWorkspace: `${FRESHDI_HOME_DOMAIN}/user/overview`,
  supplierCategoryFull: FRESHDI_HOME_DOMAIN + "/supplier-category",
  categoryFull: FRESHDI_HOME_DOMAIN + "/category",
  paymentSupplier: FRESHDI_HOME_DOMAIN + "/supplier-premium-payment",
  introTrialPlan: LANDING_DOMAIN + "/trial-plan",
  bizDomain: BIZ_DOMAIN,
  disclaimers: `${LANDING_DOMAIN}/disclaimers`,
};

export const VIEW_STANDARDS = {
  createProduct: {
    en: "https://supplier-center.freshdi.com/huong-dan/product-information-standards",
    vi: "https://supplier-center.freshdi.com/huong-dan/quy-chuan-ve-noi-dung-tao-san-pham",
  },
};

export const ROUTES = [
  {
    title: "Social Register",
    componentPath: "/business-domain/social-register",
    path: PATHS.socialRegister,
  },
];

export const BIZ_AUTHEN_ROUTES = [
  {
    componentPath: "/business-domain/dashboard",
    path: PATHS.desk,
  },
  {
    componentPath: "/business-domain/products",
    path: PATHS.products,
  },
  {
    componentPath: "/business-domain/product-detail",
    path: PATHS.products + "/:itemid",
  },
  {
    title: "My quotations",
    componentPath: "/business-domain/my-quotations",
    path: PATHS.myQuotations,
  },
  {
    componentPath: "/business-domain/inquiries",
    path: PATHS.inquiries,
  },
  {
    componentPath: "/business-domain/inquiry-detail",
    path: `${PATHS.inquiries}/:inquiryId`,
  },
  {
    title: "My quotation detail",
    componentPath: "/business-domain/my-quotations-detail",
    path: PATHS.myQuotaionDetail,
  },
  {
    title: "Contact",
    componentPath: "/business-domain/contacts",
    path: PATHS.contacts,
  },
  {
    title: "Setting",
    componentPath: "/business-domain/setting",
    path: PATHS.setting,
  },
  {
    title: "Membership",
    componentPath: "/business-domain/membership",
    path: PATHS.membership,
  },
  {
    title: "Analytics",
    componentPath: "/business-domain/analytics",
    path: PATHS.analytics,
  },
  {
    title: "Smart trace",
    componentPath: "/business-domain/smart-trace",
    path: PATHS.smartTrace,
  },
  {
    componentPath: "/business-domain/smart-trace-detail",
    path: PATHS.smartTrace + "/:postid",
  },
];

export const SOURCING_ROUTES = [
  {
    componentPath: "/sourcing-domain/buyers",
    path: PATHS.buyers,
  },
  {
    componentPath: "/sourcing-domain/buyer-detail",
    path: "/buyer/:buyerId",
  },
];

export const DIRECT_PATHS = {
  myQuotations(fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.myQuotations}`;
  },
  myQuotationDetail(id: string, fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.myQuotations}/${id}`;
  },
  quotationDetail(id: string) {
    return `/quotations/${id}`;
  },
  category(id: string) {
    return `${PATHS.categoryFull}/${id}`;
  },
  supplierCategory(id: string) {
    return `${PATHS.supplierCategoryFull}/${id}`;
  },
  categoryRfqs(category?: string, fullLink?: boolean) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${
      PATHS.sourcingRequests
    }/${category}`;
  },
  rfqDetail(id: string, fullLink: boolean = true) {
    return `${fullLink ? SOURCING_DOMAIN : ""}${PATHS.requestDetail}/${id}`;
  },
  itemDetail(id: string, fullLink?: boolean) {
    return `${fullLink ? BIZ_DOMAIN : ""}${PATHS.products}/${id}`;
  },
  buyerCategory(id: string) {
    return `${PATHS.buyerCategory}/${id}`;
  },
  buyerDetail(id: string) {
    return `${PATHS.buyers}/${id}`;
  },
  buyerFullDetail(id?: string) {
    return SOURCING_DOMAIN + "/buyer/" + id;
  },
  supplierFullDetail(id?: string) {
    return FRESHDI_HOME_DOMAIN + "/supplier/" + id;
  },
  supplierOverview() {
    return BIZ_DOMAIN + PATHS.overview;
  },
  postDetail(id: string) {
    return `${PATHS.smartTrace}/${id}`;
  },
  homeProductDetail(id: string) {
    return `${FRESHDI_HOME_DOMAIN}/product/${id}`;
  },
  inquiryDetail(id: string) {
    return `${PATHS.inquiries}/${id}`;
  },
};

export const SEARCH_PARAMS = {
  directUrl: "direct-url",
  quoteId: "quote-id",
  category_id: "category_id",
  keyword: "keyword",
  searchType: "search-type",
  tab: "tab",
  startDate: "srtdate",
  endDate: "enddate",
  is_verified: "is_verified",
  countries: "countries",
  date: "date",
  scrollTo: "sc-to",
  accountType: "acc-type",
  product: "product",
  utmSource: "utm_source",
};

export const SEARCH_PARAM_VALUES = {
  scrollToPostRfq: "rfqf",
  accountTypeBiz: "Seller",
};

export const SUBDOMAINS = {
  sourcing: process.env.REACT_APP_SOURCING_SUBDOMAIN || "",
  business: process.env.REACT_APP_BIZ_SUBDOMAIN || "",
};

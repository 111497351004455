import { Dialog } from "@mui/material";
import {
  InstructionPopup,
  LoadingSkeleton,
  MailButton,
  SwitchAccountPopup,
} from "components";
import { BREAKPOINT_VALUES } from "constants/layout";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  Suspense,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { SwitchAccountPopupType } from "types/layout";

const LayoutContext = createContext<{
  showFullMenu: boolean;
  onToggleShowFull: () => void;
  switchAccountPopup: SwitchAccountPopupType;
  onChangeSwitchAccountPopup: (data?: SwitchAccountPopupType) => void;
  showInstruction: boolean;
  setShowInstruction: Dispatch<SetStateAction<boolean>>;
}>({
  showFullMenu: false,
  onToggleShowFull: () => {},
  switchAccountPopup: { open: false },
  onChangeSwitchAccountPopup: () => {},
  showInstruction: false,
  setShowInstruction: () => {},
});

export const LayoutProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const isMobile = window.innerWidth <= BREAKPOINT_VALUES.md;

  const [showFullMenu, setShowFullMenu] = useState(isMobile ? false : true);
  const [showInstruction, setShowInstruction] = useState(false);
  const [switchAccountPopup, setSwitchAccountPopup] =
    useState<SwitchAccountPopupType>({
      open: false,
    });

  const onToggleShowFull = useCallback(() => {
    setShowFullMenu(!showFullMenu);
  }, [showFullMenu]);

  const onChangeSwitchAccountPopup = (data?: SwitchAccountPopupType) =>
    setSwitchAccountPopup({ ...switchAccountPopup, ...data });

  const onCloseSwitchAccountPopup = () =>
    setSwitchAccountPopup({ open: false });

  const contextData = useMemo(
    () => ({
      showFullMenu,
      onToggleShowFull,
      switchAccountPopup,
      showInstruction,
      setShowInstruction,
      onChangeSwitchAccountPopup,
    }),
    [
      showFullMenu,
      onToggleShowFull,
      switchAccountPopup,
      showInstruction,
      setShowInstruction,
      onChangeSwitchAccountPopup,
    ]
  );

  return (
    <LayoutContext.Provider value={contextData}>
      {children}

      {/* instruction popup */}
      <InstructionPopup />

      {/* switch account popup */}
      <Dialog
        open={switchAccountPopup.open}
        onClose={onCloseSwitchAccountPopup}
      >
        <Suspense fallback={<LoadingSkeleton count={3} m={2} />}>
          <SwitchAccountPopup
            onClose={onCloseSwitchAccountPopup}
            data={switchAccountPopup.data}
          />
        </Suspense>
      </Dialog>

      {/* Sale button */}
      <MailButton />
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);

import { Pagination, PaginationItem, PaginationProps, styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const Pagnigation = (props: PaginationProps) => {
  return <StyledPagination variant="outlined" {...props} />;
};

export const AppPagnigation = (props: PaginationProps) => {
  const location = useLocation();

  return (
    <StyledPagination
      variant="outlined"
      renderItem={(item) => {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set("page", String(item.page || 1));

        return (
          <PaginationItem
            component={Link}
            to={`${location.pathname}?${newSearchParams.toString()}`}
            {...item}
          />
        );
      }}
      {...props}
    />
  );
};

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul": {
    justifyContent: "center",
    "& .Mui-selected": {
      color: "var(--app-sub-bg-color)",
    },
    "& .MuiPaginationItem-root": {
      // backgroundColor: "#fff",
    },
  },
});

export default Pagnigation;

import { Box, Link, Stack, styled } from "@mui/material";
import { Img } from "./Img";
import { memo } from "react";
import Row from "./Row";
import { formatImg } from "helpers/format";
import { SmText, StyledA, TextSemibold, XsTextMd } from "./Text";
import { useIntl } from "react-intl";
import CountryFlag from "./CountryFlag";
import { DIRECT_PATHS } from "constants/routes";
import { CrownTooltip, VerifyTooltip } from "./Tooltip";
import TimeDiff from "./TimeDiff";
import dayjs from "dayjs";

type Props = {
  data?: {
    customer?: string;
    customer_id?: string;
    is_verified?: number;
    fd_country?: string;
    joined_time?: string;
    num_posted_rfq?: number;
    country_code?: string;
    customer_image?: string;
    is_featured?: number;
    verification_level?: string;
    last_active_datetime?: string;
  };
  preventLink?: boolean;
};

const CompanyBox = ({ data, preventLink = false }: Props) => {
  const intl = useIntl();

  const {
    customer = intl.formatMessage({
      id: "not_available_get_biz",
    }),
    fd_country,
    country_code,
    customer_image,
    customer_id,
    is_verified,
    num_posted_rfq = 0,
    is_featured = 0,
    last_active_datetime,
  } = data || {};

  return (
    <StyledCompanyBox>
      {customer_image && (
        <Img
          className="avatar"
          src={formatImg(customer_image, 2)}
          alt="avatar"
        />
      )}

      <Box>
        <Stack spacing={1} direction="row">
          {preventLink && (
            <TextSemibold className="user_name">{customer}</TextSemibold>
          )}
          {!preventLink && (
            <StyledA
              href={
                customer_id ? DIRECT_PATHS.buyerFullDetail(customer_id) : ""
              }
              className="username-link"
            >
              <TextSemibold className="user_name">{customer}</TextSemibold>
            </StyledA>
          )}
          {is_featured === 1 && (
            <CrownTooltip
              title={intl.formatMessage({
                id: "this_is_a_premium_buyer",
              })}
            />
          )}
          {is_verified === 1 && (
            <VerifyTooltip
              title={intl.formatMessage({
                id: "buyer_is_verified_by_freshdi",
              })}
            />
          )}
        </Stack>

        {last_active_datetime && (
          <SmText className="join-time">
            {intl.formatMessage(
              { id: "active_time_at" },
              {
                time: (
                  <TimeDiff
                    startTime={dayjs(last_active_datetime).format()}
                    endTime={dayjs().format()}
                  />
                ),
              }
            )}
          </SmText>
        )}

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          mt={1}
          flexWrap="wrap"
        >
          {num_posted_rfq > 0 && (
            <XsTextMd className="num-posted">
              {intl.formatMessage(
                { id: `qlt_rfq${num_posted_rfq > 1 ? "s" : ""}_posted` },
                { quantity: num_posted_rfq }
              )}
            </XsTextMd>
          )}
          <CountryFlag country={fd_country} {...{ country_code }} />
        </Stack>
      </Box>
    </StyledCompanyBox>
  );
};

const StyledCompanyBox = styled(Row)({
  ".avatar": {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".user_name": {
    color: "var(--gray-800)",
  },
  ".username-link": {
    textDecorationColor: "var(--gray-800)",
  },
  ".join-time": {
    color: "var(--gray-color)",
  },
  ".num-posted": {
    color: "#C4320A",
    backgroundColor: "#FFF6ED",
    borderRadius: 16,
    padding: "2px 8px",
  },
});

export default memo(CompanyBox);

import { IconButton, styled } from "@mui/material";

export const StyledMailButton = styled(IconButton)(({ theme }) => ({
    position: "fixed",
    zIndex: 100,
    right: 2,
    bottom: 64,
    "&,:hover": {
      backgroundColor: "var(--green-500)",
    },
    width: 40,
    height: 40,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("md")]: {
    //   right: 8,
    },
  }));
  
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { PreloadImgs } from "./Img";
import { useMemo } from "react";
import {
  BUY_PLAN_BANNER_EN_DESKTOP_IMG,
  BUY_PLAN_BANNER_EN_MOBILE_IMG,
  BUY_PLAN_BANNER_VN_DESKTOP_IMG,
  BUY_PLAN_BANNER_VN_MOBILE_IMG,
  REGISTER_BANNER_EN_DESKTOP_IMG,
  REGISTER_BANNER_EN_MOBILE_IMG,
  REGISTER_BANNER_VN_DESKTOP_IMG,
  REGISTER_BANNER_VN_MOBILE_IMG,
} from "assets/images";
import { COUNTRY_CODES } from "constants/data";
import { useLanguageContext } from "providers/mul-language";
import { OpenTabLink, StyledATag, XlTextMd } from "./Text";
import { PATHS, SEARCH_PARAM_VALUES, SEARCH_PARAMS } from "constants/routes";
import { addUtmParam, objectToQueryString } from "helpers/search";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { encodeBase64 } from "helpers/base64";
import { useIntl } from "react-intl";
import { YellowButton } from "./Button";

export const InviteRegisterBanner = (props: BoxProps) => {
  const { language } = useLanguageContext();
  const { isMobile } = useMediaBreakpoints();

  const currentBanner = useMemo(() => {
    switch (language) {
      case COUNTRY_CODES.vi:
        return isMobile
          ? REGISTER_BANNER_VN_MOBILE_IMG
          : REGISTER_BANNER_VN_DESKTOP_IMG;
      default:
        return isMobile
          ? REGISTER_BANNER_EN_MOBILE_IMG
          : REGISTER_BANNER_EN_DESKTOP_IMG;
    }
  }, [language, isMobile]);

  return (
    <>
      <PreloadImgs
        images={[
          REGISTER_BANNER_EN_DESKTOP_IMG,
          REGISTER_BANNER_EN_MOBILE_IMG,
          REGISTER_BANNER_VN_DESKTOP_IMG,
          REGISTER_BANNER_VN_MOBILE_IMG,
        ]}
      />
      <StyledATag
        href={addUtmParam(
          PATHS.registerFull +
            "?" +
            objectToQueryString({
              [SEARCH_PARAMS.accountType]: SEARCH_PARAM_VALUES.accountTypeBiz,
              [SEARCH_PARAMS.directUrl]: encodeBase64(window.location.href),
            })
        )}
      >
        <StyledInviteRegisterBanner {...props}>
          <img src={currentBanner} alt="img" />
        </StyledInviteRegisterBanner>
      </StyledATag>
    </>
  );
};

export const SuggestBuyPlanBanner = (props: BoxProps) => {
  const { language } = useLanguageContext();
  const { isMobile } = useMediaBreakpoints();

  const currentBanner = useMemo(() => {
    switch (language) {
      case COUNTRY_CODES.vi:
        return isMobile
          ? BUY_PLAN_BANNER_VN_MOBILE_IMG
          : BUY_PLAN_BANNER_VN_DESKTOP_IMG;
      default:
        return isMobile
          ? BUY_PLAN_BANNER_EN_MOBILE_IMG
          : BUY_PLAN_BANNER_EN_DESKTOP_IMG;
    }
  }, [language, isMobile]);

  return (
    <>
      <PreloadImgs
        images={[
          BUY_PLAN_BANNER_EN_DESKTOP_IMG,
          BUY_PLAN_BANNER_EN_MOBILE_IMG,
          BUY_PLAN_BANNER_VN_DESKTOP_IMG,
          BUY_PLAN_BANNER_VN_MOBILE_IMG,
        ]}
      />

      <OpenTabLink href={PATHS.supplierPricing}>
        <StyledInviteRegisterBanner {...props}>
          <img src={currentBanner} alt="img" />
        </StyledInviteRegisterBanner>
      </OpenTabLink>
    </>
  );
};

export const TryTrialPlanBanner = () => {
  const t = useIntl();
  return (
    <StyledTryTrialPlanBanner
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <XlTextMd color="var(--yellow-500)" className="title">
        {t.formatMessage({ id: "special_promotions_oct" })}
      </XlTextMd>
      <OpenTabLink href={PATHS.introTrialPlan}>
        <YellowButton>
          {t.formatMessage({ id: "special_promotions_oct_btn" })}
        </YellowButton>
      </OpenTabLink>
    </StyledTryTrialPlanBanner>
  );
};

const StyledInviteRegisterBanner = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  img: {
    aspectRatio: "10.7 / 1",
    width: "100%",
    objectFit: "cover",
  },
  padding: 0,
  [theme.breakpoints.down("md")]: {
    img: {
      aspectRatio: "6 / 1",
    },
  },
}));

const StyledTryTrialPlanBanner = styled(Stack)(({ theme }) => ({
  background: "linear-gradient(90deg, #FFF4E5 0%, #FFFFFF 52%, #FFF4E5 100%)",
  padding: "8px 16px",
  button: {
    width: "auto",
    padding: "3px 14px",
    height: 34,
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("md")]: {
    ".title": {
      fontSize: 12,
    },
    button: {
      fontSize: 10,
      padding: "0px 14px",
    },
  },
}));

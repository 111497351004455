import { ImgHTMLAttributes, useEffect } from "react";

export const Img = ({
  src,
  alt = "img",
  loading = "lazy",
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) => (
  <img src={src} alt={alt} loading={loading} {...props} />
);

const preloadImage = (url: string) => {
  const link = document.createElement("link");
  link.rel = "preload";
  link.as = "image";
  link.href = url;
  document.head.appendChild(link);
};

interface PreloadImgProps {
  images: string[];
}

export const PreloadImgs = (props: PreloadImgProps) => {
  const { images } = props;
  useEffect(() => {
    const preloadAllImages = async () => {
      try {
        await Promise.all(images.map((src) => preloadImage(src)));
      } catch (error) {
        console.error(error);
      }
    };
    if (images.length) {
      preloadAllImages();
    }
  }, [images]);
  return null;
};
